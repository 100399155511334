import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { Layout, Block } from '../components'
import { media } from '../styles/utils'
import { container, padding } from '../styles/global'
import { parseACF, Fade } from '../utils'
import { headerHeight } from '../components/Header/Header'

const DefaultPage = props => {
    const { previewData, pageData, pageContext } = props
    const data = previewData || pageData || pageContext.data
    // console.log(data)

    const renderBlocks = blocks => {
        if (!blocks) return

        return blocks.map((block, i) => {
            return (
                <Block key={i} index={i} layout={block.acf_fc_layout} {...block} />
            )
        })
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                {data && data.blocks && renderBlocks(data.blocks)}
            </Wrapper>
        </Layout>
    )
}

const Container = styled.div`
    ${container}
    ${padding}
`

const Wrapper = styled.div``

export default DefaultPage
