import React, { useState } from 'react'
import { Link, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Router, Location } from "@reach/router"

import { Layout, Footer, PrivateRoute } from '../components'
import { container, padding, bgImage, hoverState } from '../styles/global'
import { fetchData } from '../services/api';

import DefaultPage from '../templates/default-page'
import JournalSingle from '../templates/journal-single'

import { useSearchParam, useMount } from 'react-use';

const Preview = () => {
	const [data, setData] = useState(null)
	const [postType, setPostType] = useState(null)
    const postID = useSearchParam('id');
    
	useMount(() => {
		fetchData(`/pda/preview/${postID}`, true, true)
			.then(response => {		    	
				let data = response;
				setData(data)
				setPostType(data.post_type)
			})
	})

	const resolveTemplate = () => {
		if (!postType || !data) return;

		const templates = {
			'default': DefaultPage,
			'journal': JournalSingle,
		}

        const Component = templates[postType];
        
        const previewData = {
            ...data,
            ...JSON.parse(data.acf_json),
        } 

		return (
			<Component
				previewData={previewData}
			/>
		)
	}
	
	return (
		<>
			{resolveTemplate()}
		</>
	)
}

const PreviewRouter = () => {
	return (
		<Router>
			<Preview 
				path={`/preview`} 
			/>
		</Router>
	)
}

export default PreviewRouter

